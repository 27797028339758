import React, { createContext, useContext, useReducer, useRef } from "react"
import { getCopy, getProps } from "@/lib/component"
import { maybeValidUser } from "@/lib/helpers/user"

export const AppContext = createContext()

const createInitialState = ({
  pathData,
  mainElem,
  mainCTA,
  checkoutFormRef,
  searchFormRef
}) => {
  const object = getProps(pathData.props, "object")
  const copy = getCopy(pathData.copy)
  const meta = pathData?.meta ?? {}
  const adminBar = maybeValidUser()

  let crumbs = [{ label: "Startsida", uri: "/" }]

  switch (pathData.template) {
    case "archive":
      if (object.terms && object.terms.length > 0) {
        object.terms.forEach((term) => {
          crumbs.push({ label: term.label, uri: term.url })
        })
      }
      break

    default:
      if (object.parents && object.parents.length > 0) {
        object.parents.forEach((parent) => {
          crumbs.push({ label: parent.title, uri: parent.permalink })
        })
      }
      crumbs.push({ label: object.title, uri: null })
      break
  }

  return {
    mainElem,
    mainCTA,
    paymentAmount: undefined,
    hasUsedAmount: false,
    checkoutFormRef,
    searchFormRef,
    pathData,
    object: object,
    meta: meta,
    copy: copy,
    hasSentGravityForm: false,
    breadcrumbs: crumbs,
    adminBar: adminBar
  }
}

export const AppProvider = ({ pathData, children }) => {
  const mainCTA = useRef()
  const mainElem = useRef()
  const checkoutFormRef = useRef()
  const searchFormRef = useRef()

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_PAYMENT_AMOUNT": {
          return {
            ...state,
            paymentAmount: action.paymentAmount,
            hasUsedAmount: false
          }
        }

        case "SET_HAS_USED_AMOUNT": {
          return { ...state, hasUsedAmount: true }
        }

        case "SET_SKIP_AMOUNT_STEP": {
          return { ...state, skipAmountStep: action.skipAmountStep }
        }

        case "SET_HAS_SENT_GRAVITY_FORM": {
          return { ...state, hasSentGravityForm: action.hasSent }
        }

        case "SET_PATH_DATA": {
          return createInitialState({ ...state, pathData: action.pathData })
        }

        case "SET_CAMPAIGN_NAME": {
          return { ...state, campaignName: action.campaignName }
        }

        case "RESET": {
          return createInitialState(action.payload)
        }

        default:
          return state
      }
    },
    { pathData, mainElem, mainCTA, checkoutFormRef, searchFormRef },
    createInitialState
  )

  const setHasUsedAmount = () => dispatch({ type: "SET_HAS_USED_AMOUNT" })

  const setPaymentAmount = (paymentAmount) =>
    dispatch({ type: "SET_PAYMENT_AMOUNT", paymentAmount })

  const setHasSentGravityForm = (hasSent) =>
    dispatch({ type: "SET_HAS_SENT_GRAVITY_FORM", hasSent })

  const setPathData = (pathData) =>
    dispatch({ type: "SET_PATH_DATA", pathData })

  const setCampaignName = (campaignName) =>
    dispatch({ type: "SET_CAMPAIGN_NAME", campaignName })

  const resetAppContext = () => {
    dispatch({
      type: "RESET",
      payload: {
        pathData,
        mainElem,
        mainCTA,
        checkoutFormRef,
        searchFormRef
      }
    })
  }

  const setSkipAmountStep = (skipAmountStep) =>
    dispatch({ type: "SET_SKIP_AMOUNT_STEP", skipAmountStep })

  return (
    <AppContext.Provider
      value={{
        pathData: state.pathData,
        mainElem: state.mainElem,
        mainCTA: state.mainCTA,
        paymentAmount: state.paymentAmount,
        checkoutFormRef: state.checkoutFormRef,
        searchFormRef: state.searchFormRef,
        hasUsedAmount: state.hasUsedAmount,
        skipAmountStep: state.skipAmountStep,
        object: state.object,
        copy: state.copy,
        meta: state.meta,
        hasSentGravityForm: state.hasSentGravityForm,
        breadcrumbs: state.breadcrumbs,
        adminBar: state.adminBar,
        campaignName: state.campaignName,
        setPaymentAmount,
        setHasUsedAmount,
        setSkipAmountStep,
        resetAppContext,
        setPathData,
        setHasSentGravityForm,
        setCampaignName
      }}>
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  return useContext(AppContext)
}

export const usePathData = () => {
  const { pathData } = useAppContext()
  return pathData
}

export const usePathDataContent = () => {
  const { content } = usePathData()
  return content
}

export const useMeta = () => {
  const { meta } = usePathData()
  return meta
}

export const useMenus = () => {
  const { menus } = usePathData()
  return menus
}

export const usePropsObject = () => {
  const { object } = useAppContext()
  return object
}

export const useCopy = () => {
  const { copy } = useAppContext()
  return copy
}

export const useBreadcrumbs = () => {
  const { breadcrumbs } = useAppContext()
  return breadcrumbs
}

export const useAdminBar = () => {
  const { adminBar } = useAppContext()
  return adminBar
}

export default AppContext
